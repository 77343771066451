'use client';

import { PageLoadDTMEvent } from '@next-app/utils/dtm-event';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SeoItem } from '@next-app/interface/Common/index';

interface IPageData {
  title?: string;
  pageName?: string;
}

const PageLoadDTM = (props?: any) => {
  const { init, sessionInfo, seoData } = useSelector(
    (state: any) => state.init,
  );

  const {
    productFacets = {},
    assFacets = {},
    productPLPPageDetails = {},
    assPLPPageDetails = {},
    seoItem = {},
  } = props || {};

  const isEventFired = useRef(false);

  useEffect(() => {
    const hasData =
      Object.keys(sessionInfo).length > 0 &&
      Object.keys(init?.initResponse).length > 0;

    if (
      typeof window !== 'undefined' &&
      window.sendDTMEvent &&
      hasData &&
      !isEventFired.current
    ) {
      const { sessionInfo: info } = sessionInfo;
      const { profile } = info;

      const currentUrl = window?.location?.pathname || '/';
      const seoUrl = currentUrl?.split('+')?.length
        ? currentUrl?.split('+')[0]
        : currentUrl;

      const isStore = Object.keys(profile?.selectedStore).length > 0;
      let pageData: IPageData = {};
      if (seoItem?.url?.includes(seoUrl)) {
        pageData = seoItem;
      } else if (seoData && seoData?.length) {
        pageData =
          seoData?.find((item: SeoItem) => item?.url?.includes(seoUrl)) || {};
      }

      const data = {
        storeSelected: isStore
          ? `${profile?.selectedStore?.city}, ${profile?.selectedStore?.state}`
          : '',
        llmAPIVersion: init?.initResponse?.internalAPI,
        email: profile?.email || '',
        profileId: profile?.id || '',
        isGSAUser: profile?.gsaUser,
        userShopFor: profile?.userShopFor || '',
        isFurniture: info?.furniture,
        md5Email: profile?.md5Email || '',
        currentUrl: currentUrl,
        rewardsUserStatus: info?.rewardsCustomer?.tierInfo?.currentTier,
        orderId: info?.cartInfo?.cartId,
        cartCount: info?.cartInfo?.cartQty,
        recognized: profile?.loginStatus === 'recognized',
        securityStatus: profile?.securityStatus,
        pageTitle: pageData?.title || '',
        pageName: pageData?.pageName || pageData?.title || '',
        pageSection: currentUrl === '/' ? 'home' : 'category',
        pageType: currentUrl === '/' ? 'homepage' : 'category',
        billingAddress: {
          country: profile?.selectedStore?.city,
          postalCode: profile?.selectedStore?.postalCode,
          state: profile?.selectedStore?.state,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
        },
        ...productFacets,
        ...assFacets,
        ...productPLPPageDetails,
        ...assPLPPageDetails,
      };

      PageLoadDTMEvent(data);
      isEventFired.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  return null;
};

export default PageLoadDTM;
