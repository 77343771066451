'use client';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import { hideDynamicModal } from '@next-app/utils/common-utils';
import Button from '../elements/Button/Button';
import Conditional from '../Conditional';
import DynamicModal from '../elements/DynamicModal/DynamicModal';

import styles from './ConfirmDeletedProfile.module.scss';

const ConfirmDeletedProfile = () => {
  const searchParams = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (searchParams.get('profile-deleted') === 'true') {
      setIsOpen(true);
      const modalElement = document?.getElementById(
        'dynamicModalWrapper',
      ) as HTMLElement;
      if (modalElement) {
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.show();
      }
    }
  }, [searchParams]);

  const handleClose = () => {
    setIsOpen(false);
    hideDynamicModal();

    // Remove the query parameter from the URL
    const currentUrl = window.location.href;
    const replaceURl = currentUrl.replace('?profile-deleted=true', '');
    window.history.pushState(null, '', replaceURl);
  };

  return (
    <Conditional if={isOpen}>
      <DynamicModal
        modalContentClass={`${styles['modal-deleted']}`}
        modalTitle="Your Account Has Been Deleted"
        onModalClose={handleClose}
      >
        <div>
          <p>
            <b>Your web account has been deleted.</b>
            <br />
            <br />
            Please note that it may take up to 7 days to complete your account
            deletion request. You will not be able to create a new account using
            your email during that time. Deleting your web account will delete
            your web profile but will not delete your email preferences, catalog
            subscriptions, purchase history, or personal information from all of
            our systems. If you wish to delete any of your additional personal
            information that we maintain, please contact our Customer Service
            Department. We will maintain a record of your request as required by
            law, and some information may be retained as described in our{' '}
            <a href="/about/privacy-policy" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
            <br />
            <br />
            If this was done in error or if you have any questions, please
            contact our Customer Service Department for assistance.
          </p>
        </div>
        <div className={styles['modal-footer']}>
          <Button
            type="button"
            variant="primary"
            data-bs-dismiss="modal"
            data-dismiss="dynamicModalWrapper"
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </DynamicModal>
    </Conditional>
  );
};

export default ConfirmDeletedProfile;
